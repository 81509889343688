import React from "react";
import { Link } from "gatsby";

export const MenuNA = ({
  site,
  loginWithRedirect,
  isAuthenticated,
  logout,
}) => {
  return (
    <>
      {site.data.menu
        .filter((item) => item.name !== "Beiträge")
        .map((link, index) => (
          <Link
            to={link.to}
            key={index}
            className="first:ml-0 ml-8 text-base font-medium border-b-2 pb-2 border-transparent text-gray-700 hover:scale-110 transition transform duration-500 dark:text-white"
            activeClassName="border-blue-300 text-gray-900"
            partiallyActive={index > 0}
          >
            {link.name}
          </Link>
        ))}
      {isAuthenticated && (
        <button
          onClick={logout}
          className="ml-8 text-base font-medium border-b-2 pb-2 border-transparent text-gray-700 hover:scale-110 transition transform duration-500 dark:text-white"
        >
          Logout
        </button>
      )}
      {!isAuthenticated && (
        <button
          onClick={loginWithRedirect}
          className="ml-8 text-base font-medium border-b-2 pb-2 border-transparent text-gray-700 hover:scale-110 transition transform duration-500 dark:text-white"
        >
          Login
        </button>
      )}
    </>
  );
};
