import React from "react";
import { Link } from "gatsby";

export const Logo = ({ LogoImage }) => {
  return (
    <Link to="/">
      <div className="flex justify-center md:justify-start items-center lg:w-60">
        <img
          className="mr-3"
          src={LogoImage}
          alt="Logo"
          width={56}
          height={56}
        />
        <div className="text-4xl hidden md:contents font-serif">Blog</div>
      </div>
    </Link>
  );
};
