import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { useAuth0 } from "@auth0/auth0-react";
import { checkRole } from "./Utils";

const Footer = () => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        data: siteMetadata {
          menu {
            name
            to
          }
        }
      }
    }
  `);

  const { isAuthenticated, user, loginWithRedirect, logout } = useAuth0();

  const MenuStandard = () => {
    return (
      <>
        {site.data.menu.map((link, key) => (
          <Link
            key={`menu_desktop_link${key}`}
            className="ml-2 text-gray-400 text-xs"
            activeClassName="text-gray-500 dark:text-gray-300"
            to={link.to}
            partiallyActive={key > 0}
          >
            {link.name}
          </Link>
        ))}
        <button onClick={logout} className="ml-2 text-gray-400 text-xs">
          Logout
        </button>
      </>
    );
  };

  const MenuNotActivated = () => {
    return (
      <>
        {site.data.menu
          .filter((item) => item.name !== "Beiträge")
          .map((link, key) => (
            <Link
              key={`menu_desktop_link${key}`}
              className="ml-2 text-gray-400 text-xs"
              activeClassName="text-gray-500 dark:text-gray-300"
              to={link.to}
              partiallyActive={key > 0}
            >
              {link.name}
            </Link>
          ))}
        <button onClick={logout} className="ml-2 text-gray-400 text-xs">
          Logout
        </button>
      </>
    );
  };

  const MenuAdmin = () => {
    return (
      <>
        {site.data.menu
          .filter((item) => item.name !== "Kontakt")
          .map((link, key) => (
            <Link
              key={`menu_desktop_link${key}`}
              className="ml-2 text-gray-400 text-xs"
              activeClassName="text-gray-500 dark:text-gray-300"
              to={link.to}
              partiallyActive={key > 0}
            >
              {link.name}
            </Link>
          ))}
        <Link to="/notizen" className="ml-2 text-gray-400 text-xs">
          Notizen
        </Link>
        <button onClick={logout} className="ml-2 text-gray-400 text-xs">
          Logout
        </button>
      </>
    );
  };

  const MenuNotLoggedIn = () => {
    return (
      <>
        {site.data.menu
          .filter((item) => item.name !== "Beiträge")
          .map((link, key) => (
            <Link
              key={`menu_desktop_link${key}`}
              className="ml-2 text-gray-400 text-xs"
              activeClassName="text-gray-500 dark:text-gray-300"
              to={link.to}
              partiallyActive={key > 0}
            >
              {link.name}
            </Link>
          ))}
        <button
          onClick={loginWithRedirect}
          className="ml-2 text-gray-400 text-xs"
        >
          Login
        </button>
      </>
    );
  };

  const Copyright = () => {
    return (
      <div className="mt-8 md:mt-0 md:order-1">
        <p className="text-center text-xs text-gray-400">
          &copy; 2021 blog.martinvu.de. All rights reserved.
        </p>
      </div>
    );
  };

  return (
    <div className="container py-12 md:flex md:items-center md:justify-between mt-auto">
      <div className="flex justify-center md:order-2">
        {!checkRole(user, "admin") && checkRole(user, "standardbenutzer") && (
          <MenuStandard />
        )}
        {isAuthenticated && !checkRole(user) && <MenuNotActivated />}
        {checkRole(user, "admin") && <MenuAdmin />}
        {!isAuthenticated && <MenuNotLoggedIn />}
      </div>
      <Copyright />
    </div>
  );
};

export default Footer;
