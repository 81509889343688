import React from "react";
import { Link } from "gatsby";

export const Menu = ({ checkRole, user, logout }) => {
  return (
    <>
      <Link
        to="/"
        className="ml-8 text-base font-medium border-b-2 pb-2 border-transparent text-gray-700 transition transform duration-500 dark:text-white"
        activeClassName="border-blue-300 text-gray-900"
      >
        Home
      </Link>
      <div className="group relative ml-8">
        <Link
          to="/beitraege"
          className="text-base font-medium border-b-2 pb-2 border-transparent text-gray-700 dark:text-white"
          activeClassName="border-blue-300 text-gray-900"
          partiallyActive={true}
        >
          Beiträge
        </Link>
        <ol className="absolute invisible group-hover:visible duration-100 bg-blue-300 dark:bg-gray-700 rounded-md p-2 w-max left-1/2 transform -translate-x-1/2 translate-y-4 font-medium z-50">
          <li className="mb-2">
            <Link
              to="/beitraege"
              className="text-white border-l-2 pl-2 border-transparent pr-2.5"
              activeClassName="border-white"
            >
              Alle Beiträge
            </Link>
          </li>
          <li className="mb-2">
            <Link
              to="/beitraege/reihen"
              className="text-white border-l-2 pl-2 border-transparent"
              activeClassName="border-white"
            >
              Reihen
            </Link>
          </li>
          <li className="mb-2">
            <Link
              to="/beitraege/vorschau"
              className="text-white border-l-2 pl-2 border-transparent"
              activeClassName="border-white"
            >
              Vorschau
            </Link>
          </li>
        </ol>
      </div>
      {checkRole(user, "admin") && (
        <Link
          to="/notizen"
          className="ml-8 text-base font-medium border-b-2 pb-2 border-transparent text-gray-700 dark:text-white"
          activeClassName="border-blue-300 text-gray-900"
        >
          Notizen
        </Link>
      )}
      {!checkRole(user, "admin") && (
        <Link
          to="/kontakt"
          className="ml-8 text-base font-medium border-b-2 pb-2 border-transparent text-gray-700   dark:text-white"
          activeClassName="border-blue-300 text-gray-900"
        >
          Kontakt
        </Link>
      )}

      <button
        onClick={logout}
        className="ml-8 text-base font-medium border-b-2 pb-2 border-transparent text-gray-700 dark:text-white"
      >
        Logout
      </button>
    </>
  );
};
