import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Scroll from "../components/Scroll";

const Layout = ({ children, setFilteredKeyword, unlocked }) => {
  return (
    <div className="min-h-screen flex flex-col dark:bg-black">
      {<Header setFilteredKeyword={setFilteredKeyword} unlocked={unlocked} />}
      {children}
      <Scroll showBelow={250} />
      <Footer />
    </div>
  );
};

export default Layout;
