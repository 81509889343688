import Coffee from "../../static/buymeacoffee.png";
import React from "react";
import { Spinner } from "@chakra-ui/react";

export const monthDict = {
  "01": "Januar",
  "02": "Februar",
  "03": "März",
  "04": "April",
  "05": "Mai",
  "06": "Juni",
  "07": "Juli",
  "08": "August",
  "09": "September",
  10: "Oktober",
  11: "November",
  12: "Dezember",
};

export function gerFormat(a) {
  return `${parseInt(a.split("-")[0])}. ${monthDict[a.split("-")[1]]} ${
    a.split("-")[2]
  }`;
}
export const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

export function gerFormatShort(a) {
  return `${parseInt(a.split("-")[0])}. ${monthDict[a.split("-")[1]].substring(
    0,
    3
  )} ${a.split("-")[2]}`;
}

export const checkRole = (user, role) => {
  if (!user) return false;
  if (role) return user["http://localhost:8000/"]?.role === role;
  return user["http://localhost:8000/"]?.role;
};

export const userName = (user) => {
  if (!user.name) {
    return false;
  } else {
    return user["http://localhost:8000/"]?.name;
  }
};

export const MySpinner = () => {
  return (
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
      <Spinner
        thickness="4px"
        speed="1.3s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </div>
  );
};

export const BuyMeACoffee = () => {
  return (
    <div className="p-3 bg-yellow-100 dark:bg-gray-700 md:bg-white md:dark:bg-gray-800 rounded-lg shadow-sm">
      <a
        href="https://www.buymeacoffee.com/martinvu"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="flex items-center">
          <img
            className="mr-2"
            src={Coffee}
            alt="buymeacoffee"
            placeholder="blurred"
            width={20}
          />
          <p className="dark:text-white">Buy me a coffee :)</p>
        </div>
      </a>
    </div>
  );
};
