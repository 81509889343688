import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { FaBars } from "react-icons/fa";
import MenuMobile from "./MenuMobile";
import LogoImage from "../../static/mv.png";
import { navigate } from "gatsby";
import { useAuth0 } from "@auth0/auth0-react";
import { FaLock } from "react-icons/fa";
import { checkRole, userName } from "./Utils";
import { UserInfoMobile } from "./UserInfoMobile";
import { Logo } from "./Logo";
import { Menu } from "./Menu";
import { MenuNA } from "./MenuNA";
import { Input } from "@chakra-ui/react";

const Header = ({ setFilteredKeyword, unlocked }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { site } = useStaticQuery(graphql`
    query {
      site {
        data: siteMetadata {
          menu {
            name
            to
          }
        }
      }
    }
  `);

  const {
    loginWithRedirect,
    isAuthenticated,
    logout,
    isLoading,
    user,
  } = useAuth0();
  const [info, setInfo] = useState(false);

  const [keyword, setKeyword] = useState("");
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (keyword.length < 3) {
        setInfo(true);
      }
      if (keyword.length >= 3) {
        if (setFilteredKeyword) {
          setFilteredKeyword(keyword);
          e.target.value = "";
        }
        if (!setFilteredKeyword) {
          navigate("/beitraege", { state: { keyword: keyword } });
        }
      }
    }
    if (info && e.key !== "Enter") {
      setInfo(false);
    }
  };

  const Searchbar = () => {
    return (
      checkRole(user) && (
        <div className="hidden lg:contents">
          <div className="w-32 relative">
            <Input
              placeholder="Beiträge suchen"
              size="xs"
              variant="flushed"
              onChange={(e) => setKeyword(e.target.value)}
              onKeyDown={handleKeyPress}
            />
            <div className="text-xs text-gray-400 absolute">
              {info && "mindestens 3 Zeichen"}
            </div>
          </div>
        </div>
      )
    );
  };

  const UserInfo = () => {
    return (
      <div className="hidden lg:contents">
        <div className="lg:h-8 bg-gray-50 dark:bg-gray-900 flex items-center">
          {isAuthenticated && (
            <div className="container text-xs flex justify-between relative">
              {userName(user) ? (
                <div className="dark:text-white opacity-40">
                  {`Hallo ${userName(user)}!`}
                </div>
              ) : (
                <div className="dark:text-white opacity-40">{`Bonjour!`}</div>
              )}
              <div className="absolute left-1/2 transform -translate-x-1/2 capitalize opacity-40 dark:text-white">
                {user["http://localhost:8000/"]?.role2
                  ? user["http://localhost:8000/"]?.role2
                  : checkRole(user)}
              </div>
              {!checkRole(user) && (
                <div className="text-red-600">
                  Warte auf Bestätigung eines Admins.
                </div>
              )}
              <div className="dark:text-white opacity-40">{user?.email}</div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const Lock = () => {
    return (
      !isAuthenticated &&
      !unlocked && (
        <button onClick={loginWithRedirect}>
          <FaLock className="absolute m-auto left-0 right-0 top-0 bottom-0 animate-bounce" />
        </button>
      )
    );
  };

  const HamburgerIcon = () => {
    return (
      <button
        className="md:hidden"
        onClick={() => setIsMenuOpen(true)}
        aria-label="Open Menu"
      >
        <FaBars className="h-6 w-auto text-gray-900 fill-current dark:text-white" />
      </button>
    );
  };

  const InfoBar = () => {
    return (
      <div className="hidden md:contents">
        <div className="flex lg:w-60 lg:justify-end">
          {isLoading ? null : (
            <>
              {checkRole(user) && (
                <Menu checkRole={checkRole} user={user} logout={logout} />
              )}
              {(!checkRole(user) || !isAuthenticated) && (
                <MenuNA
                  site={site}
                  isAuthenticated={isAuthenticated}
                  logout={logout}
                  loginWithRedirect={loginWithRedirect}
                />
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="flex dark:text-white">
        <div className="container py-3 flex justify-between items-center relative">
          <Logo LogoImage={LogoImage} />
          {isLoading ? null : (
            <>
              <Lock />
              {checkRole(user) && (
                <UserInfoMobile
                  user={user}
                  userName={userName}
                  logout={logout}
                />
              )}
              {Searchbar()}
            </>
          )}
          <HamburgerIcon />
          <InfoBar />
        </div>
        <MenuMobile
          isOpen={isMenuOpen}
          setIsOpen={setIsMenuOpen}
          links={site.data.menu}
        />
      </div>
      <UserInfo />
    </div>
  );
};

export default Header;
