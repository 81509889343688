import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Overlay from "./Overlay";
import { useAuth0 } from "@auth0/auth0-react";
import { checkRole } from "./Utils";

const menuItem = {
  closed: {
    opacity: 0,
    transition: {
      delay: 0,
      duration: 0,
    },
    x: -20,
  },
  open: (key) => ({
    opacity: 1,
    transition: {
      delay: 0.01 + key * 0.08,
      type: "tween",
    },
    x: 0,
  }),
};

const MenuMobile = ({ links, isOpen, setIsOpen }) => {
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();

  return (
    <Overlay isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="container flex flex-col justify-center">
        <ul className="text-center">
          {isAuthenticated && (
            <>
              {checkRole(user) &&
                links
                  .filter(
                    (item) =>
                      item.name !==
                      (checkRole(user, "admin") ? "Kontakt" : item)
                  )
                  .map((link, key) => (
                    <li
                      className="my-3"
                      animate={isOpen ? "open" : "closed"}
                      custom={key}
                      key={`menu_mobile_link${key}`}
                      variants={menuItem}
                    >
                      <Link
                        className="font-semibold text-4xl text-white"
                        activeClassName="text-blue-900 dark:text-blue-500"
                        to={link.to}
                        onClick={() => setIsOpen(false)}
                        partiallyActive={key > 0}
                      >
                        {link.name}
                      </Link>
                    </li>
                  ))}
              {checkRole(user, "admin") && (
                <li className="mb-3">
                  <Link
                    to="/notizen"
                    className="font-semibold text-4xl text-white"
                  >
                    Notizen
                  </Link>
                </li>
              )}
            </>
          )}
          {(!isAuthenticated || !checkRole(user)) &&
            links
              .filter((item) => item.name !== "Beiträge")
              .map((link, key) => (
                <li
                  className="my-3"
                  animate={isOpen ? "open" : "closed"}
                  custom={key}
                  key={`menu_mobile_link${key}`}
                  variants={menuItem}
                >
                  <Link
                    className="font-semibold text-4xl text-white"
                    activeClassName="text-blue-900 dark:text-blue-500"
                    to={link.to}
                    onClick={() => setIsOpen(false)}
                    partiallyActive={key > 0}
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
          {isAuthenticated ? (
            <li>
              <button
                className="font-semibold text-4xl text-white"
                onClick={logout}
              >
                Logout
              </button>
            </li>
          ) : (
            <li>
              <button
                className="font-semibold text-4xl text-white"
                onClick={loginWithRedirect}
              >
                Login
              </button>
            </li>
          )}
        </ul>
      </div>
    </Overlay>
  );
};

MenuMobile.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    })
  ),
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default MenuMobile;
