import React from "react";
export const UserInfoMobile = ({ user, logout, userName }) => {
  return (
    <div className="absolute m-auto left-0 right-0 top-0 bottom-0 group lg:hidden w-8 h-8">
      <div className="relative">
        <img
          src={user.picture}
          alt={user.name}
          width="30"
          height="30"
          className="rounded-full"
        />
        <div className="absolute bg-blue-200 dark:bg-gray-800 left-1/2 transform -translate-x-1/2 translate-y-1 rounded-lg p-3 hidden group-hover:block z-100">
          <div className="capitalize text-center dark:text-gray-400">
            {userName(user)}
          </div>
          <div className="text-center dark:text-gray-400">{user.email}</div>
          <div className="text-center">
            <button className="text-red-600 font-bold text-sm" onClick={logout}>
              Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
